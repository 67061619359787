
//DAN's API and Framework code (Part 1)
//In TypeScript. See TypeScript version on left and JavaScript transpiled version (though with comments, etc.) in sub-page in OneNote.
//You can convert TypeScript to JavaScript, and even code (with online IDE/editor) and test run here:
//http://www.typescriptlang.org/play/
//Or add search keyword to browser to convert any text, like:
//http://www.typescriptlang.org/play/#src=%s
//TODO-DOUG: Integrate the following into API use and for rowpairArr in-place and other refactorings and parts of the API needed next.
//rowpairsArr needs to be factored later at least to a class instance for each row like:

//deasync seems to have regex null error, and warn about missing fs package, so commented out waitForAsync
//import * as deasync from "deasync";

//import { runLoopOnce } from 'deasync';
//require() function (should be implemented by webpack since not running under node.js), but doesn't seem to be (maybe need full Webpack compiling setup)
//declare function require(name: string): any;
//alternative to following since don't want to rely on other node functions when running as web app/Add-in instead almost always
//import * from 'node'


// #region Enums (4)

enum ColumnUsage {
    Default, //should default to Sum for Currency, and either Sum or FirstOf (depending on settings) for other numbers, FirstOf for text types
    MatchBy,
    MatchByTotal,
    GroupBy,
    TotalBy, //Shown, and forces Sum if/when grouping is used
    FirstOf, //Shown, forces First (or maybe last?) of values to be shown when grouping
    Removed //use instead of Hidden because actually affects calculation and prevents from being used/exported anywhere, unlike upcoming cosmetic Show/Hide/Group per View/Report
}

enum AggregateMethod {
    Default,
    Sum,
    LastOf,
    FirstOf
}
enum DataType {
    Unknown,
    string, //match string type name
    number, //match number type name
    Date, //match Date type name
    Array, //match Date type name, or could have ArrayNumbers, RowArray, etc.
}

enum ValueFormat {
    Raw,
    Text,
    Legacy,
    HTML,
    UniqueValueArray //needed? Or else for Row#? and maybe Group By?
}

enum LogLevel {
    Verbose,
    Debug,
    Info,
    WarnMinor,
    Warn,
    WarnMajor,
    ErrorMinor,
    Error,
    ErrorMajor,
    Critical,
    CriticalShowUser
}

//Bitwise flags with shift << operator allow us to check if DataPrepExport also contains DataPrep and/or Export flags, and combine via | operator.
//Enums in TypeScript are objects at runtime that have properties that go from int -> string and from string -> int for all possible values. So ViewUsage.Unknown = 0, ViewUsage[0] = "Unknown", ViewUsage["Unknown"] = 0.
//if(usage & ViewUsage.ApiOutput = ViewUsage.ApiOutput) is true if contains the full value. or if(usage & (ViewUsage.Report | ViewUsage.Export)) is true if contains either of those flags. | combines values (use instead of +).

enum ViewUsage {
    // tslint:disable: no-bitwise
    Unknown = 0,
    AllFields = 1 << 0, //the first bit in
    InputTable = 1 << 1,
    Default = 1 << 2,
    DataPrep = Default, //may replace Default because comes after so that converting enum to string returns "DataPrep" instead of "Default"
    Report = 1 << 3,
    Export = 1 << 4,
    ApiOutput = 1 << 5 | Export,
    FullReport = 1 << 6 | Report,
    SelectionReport = 1 << 7 | Report,
    DefaultReport = 1 << 8 | Report,
    CustomReport = 1 << 9 | Report,
    SubView = 1 << 10,
    LeftSide = 1 << 11,
    RightSide = 1 << 12,
    LeftTable = LeftSide | InputTable,
    RightTable = RightSide | InputTable,
    ExportSubView = Export | SubView,
    DefaultFullReport = DefaultReport | FullReport,
    DefaultSelectionReport = DefaultReport | SelectionReport,
    CustomFullReport = CustomReport | FullReport,
    CustomSelectionReport = CustomReport | SelectionReport,
    DataPrepExport = DataPrep | Export
    // tslint:enable: no-bitwise
}

// #endregion

//Type Aliases:

type MapLike<K, T> = { K: T }
type StringMap = { [key: string]: string; };
type StringObjectMap = { [key: string]: object; };
type StringAnyMap = { [key: string]: any; };


// #region Logger class and global variable

class MessagePromptEvent {

    //set to true if want to skip showing via default method (eg. alert()) if already was shown to user
    preventShowing = false;

    //NOTE: Use of public for constructor parameters implicitly creates and sets those fields on this class
    constructor(public message = '', public timeStampLogLevelPrefix = '', public error?: Error,
        public level: LogLevel = LogLevel.Info, public title = '', public errorDescription = '', public messageFormated = '') {

    }
}
type ShowMessageHandler = (messageEvent: MessagePromptEvent) => void;

class Logger {

    //Constants:

    AppPrefix = '[PowerAnalytics.ai] ';
    readonly NewLine = '\n';


    public prefixByLogLevel: string[] = [];

    //Fields: (Options)
    prefixWithAppName = false;
    //TODO: Timestamp prefixes
    timeStamps = true; //OR: Debug mode only?
    MinLogLevel = LogLevel.Verbose;
    MinLevelToShowUser = LogLevel.CriticalShowUser; //OR: Critical, or maybe Error for Debug mode
    messageBoxTitle = 'Warning';
    UseAlertForShowUserFallback = true;

    onShowToUser: ShowMessageHandler;
    timeStampPrefix = '[';
    timeStampSuffix = '] ';
    showTimeStampLogLevelInPrompt = false;
    stackTracePrefix = '  Stack Trace:\n';

    onTimeStampFormat: (date: Date) => string;

    constructor() {
        this.prefixByLogLevel[LogLevel.CriticalShowUser] = '[CRITICAL-SHOW] ';
        this.prefixByLogLevel[LogLevel.Critical] = '[CRITICAL] ';
        this.prefixByLogLevel[LogLevel.ErrorMajor] = '[ERROR] ';
    }

    //Public Methods:
    public criticalShowUser(message: string, level = LogLevel.CriticalShowUser, error?: Error, condition = true) { //, messageBoxTitle
        this.info(message, level, error, condition);
    }

    public critical(message: string, level = LogLevel.Critical, error?: Error, condition = true) {
        this.info(message, level, error, condition);
    }

    public error(message: string, level = LogLevel.Error, error?: Error, condition = true) {
        this.info(message, level, error, condition);
    }

    public warn(message: string, level = LogLevel.Warn, error?: Error, condition = true) {
        this.info(message, level, error, condition);
    }

    public debug(message: string, level = LogLevel.Debug, error?: Error, condition = true) {
        this.info(message, level, error, condition);
    }

    public verbose(message: string, level = LogLevel.Verbose, error?: Error, condition = true) {
        this.info(message, level, error, condition);
    }

    public info(message: string, level = LogLevel.Info, error?: Error, condition = true, messageBoxTitle = this.messageBoxTitle) {

        if (!condition) {
            return;
        }
        if (!message) {
            return;
        }

        if (level < this.MinLogLevel) {
            return;
        }

        let prefix = this.getTimeStampOrPrefix(level);

        //add prefix, avoiding string concat if not needed
        let prefixedMessage = (prefix) ? prefix + message : message;

        //append error description, if any, to the end
        let errorDesc = '';
        if (error) {
            errorDesc = this.getErrorDescription(error);
            prefixedMessage = prefixedMessage + this.NewLine + errorDesc;
        }

        //log the message, with it showing up in JS Console based filter buttons there, based on whether use console.error vs. log vs. warn vs. debug

        if (level >= LogLevel.ErrorMinor) {

            console.error(prefixedMessage);

        } else if (level >= LogLevel.WarnMinor) {

            console.warn(prefixedMessage);

        } else if (level <= LogLevel.Verbose) { //LogLevel.Debug //NOTE: debug only displayed in Chrome console if have Verbose consoler filter enabled
           
            //always safe to use, since tslint warns about debug, or need to fallback to .log()?
            //only displayed when have Verbose console filter enabled

            console.debug(prefixedMessage); // tslint:disable-line: no-console

        } else {

            console.log(prefixedMessage);

        }

        if (level > this.MinLevelToShowUser) {

            let prompt = new MessagePromptEvent(message, prefixedMessage, error, level, messageBoxTitle, errorDesc);
            
            this.showToUserWithoutLog(prompt);
        }
    }
    
    private getTimeStampOrPrefix(level: LogLevel): string {

        //lookup prefix string by LogLevel
        let prefix = this.prefixByLogLevel[level];

        if (this.prefixWithAppName) {
            prefix = this.AppPrefix + prefix;
        }
        //add timestamp if desired
        if (this.timeStamps) {
            let stamp = '';
            let date = new Date();
            if (this.onTimeStampFormat) {
                stamp = this.onTimeStampFormat(date);
            } else {
                stamp = date.toLocaleTimeString(); //"1:12:03 PM"
            }
            if (stamp) {
                prefix = this.timeStampPrefix + stamp + this.timeStampSuffix + prefix;
            }
        }
        return prefix;
    }
    public getErrorDescription(error: Error): string {
        if (!error) return '';
        let desc = error.toString();

        //desc = 'Error:' + this.NewLine + desc;
        //Error.toString() is already prefixed with "Error: " if error.name = default of "Error"
        if (error.stack) {
            desc = desc + this.NewLine + this.stackTracePrefix + error.stack;
        }
        return desc;
    }
    public showToUserWithoutLog(prompt: MessagePromptEvent): boolean {

        //assumes default constructor sets prompt.preventShowing = false;
        
        if (prompt.preventShowing) {
            return false;
        }

        //if has custom user callback
        if (this.onShowToUser) {

            //prompt.preventShowing = true; //instead rely on user to set that if/when they handle the showing (instead of just formatting)

            //call the custom user callback to either show or format the message
            this.onShowToUser(prompt);

            if (prompt.preventShowing) {
                //assume the user handled showing already
                return true;
            }

        }

        //TODO: Implement another method via Popper NPM package, etc. or other way to show a message, then return to skip fallback
        //if(popper)
        //return true;

        if (this.UseAlertForShowUserFallback) {

            let formatted = prompt.messageFormated;

            //if user callback didn't already pre-format it, combine all the details into one message
            if (!formatted) {
                formatted = prompt.message;

                if (this.showTimeStampLogLevelInPrompt && prompt.timeStampLogLevelPrefix) {
                    formatted = prompt.timeStampLogLevelPrefix + formatted;
                }
                if (prompt.title) {
                    formatted = prompt.title + this.NewLine + formatted;
                }
                
                if (prompt.errorDescription) {
                    formatted = formatted + this.NewLine + prompt.errorDescription;
                } else if (prompt.error) {
                    formatted = formatted + this.NewLine + this.getErrorDescription(prompt.error);
                }
            }
            
            //WARNING: alert() is a blocking call, preventing JS from executing until user closes, which is why need to implement alternative method of showing user via overlay
            alert(formatted);

            return true;
        }

        return false;

    }

}


//GLOBALS:

//Define global variable for primary global object representing the PowerAnalytics application and API
//NOTE: Delay calling PowerAnalytics constructor until very bottom of file, after all classes it depends on are defined
//however, that means we have to use var instead of const here (so can't prevent reassigning unless make it a property)
//PowerAnalytics constructor will set this app variable and also PowerAnalytics.instance static field first thing, so occurs before places app variable is used in constructors of DataTable etc. classes
let app: PowerAnalytics; // = PowerAnalytics.instance = new PowerAnalytics();



class Utility {

    //deasync seems to have regex null error, and warn about missing fs package, so commented out waitForAsync  
    //private loopSafe: () => void = deasync.runLoopOnce; //require('deasync').runLoopOnce; //see import statement at top of file

    constructor() {

    }

    // waitForAsync<T>(promise: PromiseLike<T>, throwOnError = false,
    //      onCompleted?: (result: T) => void,
    //      onError?: (error: Error) => void)
    //      : T | Error {

    //     let result: T | undefined;
    //     let error: Error | undefined;
    //     let done = false;

    //     promise.then((res) => {
    //         result = res;
    //     }, (err) => {
    //         error = err;
    //     }).then(() => {
    //         done = true;
    //     });

    //     while (!done) {
    //         this.loopSafe();
    //     }

    //     if (error) {
    //         if (throwOnError) {
    //             throw error;
    //         } else {
    //             return error;
    //         }
    //     }
    //     return result as T;
    // }

    // public sleep(milliseconds: number) {
    //     sleep()
    // }
}

//GLOBALS: Logger class instance (also set to log.warning on PowerAnalytics class)
let log = new Logger();
// #endregion

type CookieConverter = (value: string, name: string) => string;


class Persistence {

    daysPerYear = 365;
    defaultCookieExpireDays = 10 * this.daysPerYear;
    millisecondsPerDay = 864e+5; //24 * 60 * 60 * 1000;
    hasDoc = false; //document might not exist with node.js
    hasWindow = false; //document might not exist with node.js
    hasLocalStorage = false; //may not be available when use file:// url, at least in IE
    cookieSupported: boolean | undefined;
    logErrorIfCookiesNotSupported = false;
    //MAYBE: only cache if don't care about and if avoid replacing _ga and _gid Google Analytics cookies when setting again, and if doesn't cause too much memory overhead
    cacheCookies = true;

    readCookieConverter: CookieConverter;
    writeCookieConverter: CookieConverter;

    cookieAttributeDefaults: StringAnyMap = {};
    fallbackStorageToCookies = true;

    constructor() {
        this.hasDoc = (!!document);
        this.hasWindow = (!!window);
        this.hasLocalStorage = this.hasWindow && !!window.localStorage;

    }

    public getLocalStorageOrCookieJson(name: string, fallbackToCookies = this.fallbackStorageToCookies): object {
        
        let value = this.getLocalStorageOrCookie(name, fallbackToCookies);
        if (value) {
            //TODO: Try-catch:
            let obj = JSON.parse(value) as object;
            if (obj !== undefined && obj !== null) {
                return obj;
            }
        }

        return {};
    }

    public setLocalStorageOrCookieJson(name: string, value: any, fallbackToCookies = this.fallbackStorageToCookies): object {
        
        //TODO: Try-catch:
        let obj = JSON.stringify(value);

        //TODO: Try-catch:
        this.setLocalStorageOrCookie(name, obj, fallbackToCookies);

        return {};
    }
    public getLocalStorageOrCookie(name: string, fallbackToCookies = this.fallbackStorageToCookies): string {

        let value = '';
        if (!name) {
            return value;
        }

        if (this.hasLocalStorage) {
            try {
                value = window.localStorage.getItem(name) || '';
                return value;
            } catch (error) {
                log.warn(`Failed to get item '${name}' from localStorage, fallback to cookies = ${fallbackToCookies}`);
            }
        }
        if (this.fallbackStorageToCookies) {
            value = this.getCookie(name);
        }

        return value;
    }

    public setLocalStorageOrCookie(name: string, value: any, fallbackToCookies = this.fallbackStorageToCookies): boolean {

        if (!name) {
            return false;
        }

        if (this.hasLocalStorage) {
            try {
                value = window.localStorage.setItem(name, value);
                return value;
            } catch (error) {
                log.warn(`Failed to set '${name}' = '${value}' in localStorage, fallback to cookies = ${fallbackToCookies}`);
            }
        }
        if (this.fallbackStorageToCookies) {
            value = this.setCookie(name, value);
        }

        return value;
    }

    private tryGetCookiesRaw(): string {
        
        let cookiesRaw = '';

        if (this.cookieSupported === false) { //if set to false, not just undefined (unknown)
            log.verbose('Cookies are not supported, possibly due to security restrictions');
            return cookiesRaw;
        }

        if (this.hasDoc) {
            try {
                cookiesRaw = document.cookie;

                //if first time we've tried
                if (this.cookieSupported === undefined) {
                    //confirm its supported, with this check required to prevent logging an error every time if fails
                    this.cookieSupported = true;
                }
                return cookiesRaw;

            } catch (error) {
                //if first attempt ever
                if (this.cookieSupported === undefined) {

                    //prevent trying again
                    this.cookieSupported = false;
                    log.warn('Failed to get document.cookie first time tried, so will not try again, assuming not supported,  possibly due to security restrictions', LogLevel.Warn, error);
                
                } else {

                    //was able to do it before, so its a one-time error
                    log.error('Failed to get document.cookie even though had succeeded before', LogLevel.Error, error);
                }
            }
        }

        return cookiesRaw;
    }
    getDaysFromNow(days: number): Date {
        let d = new Date();
        d.setTime(d.getTime() + this.millisecondsPerDay * days);
        return d;
    }

    deleteCookie(name: string) {
        this.setCookie(name, '');
    }

    // extend () {
    //     let i = 0;
    //     let result = {} //: { [key: any]: any; } = {};
    //     for (; i < arguments.length; i++) {
    //         let attributes = arguments[ i ];
    // // tslint:disable-next-line: forin
    //         for (let key in attributes) {
    //             let attribVal = attributes[key];
    //             result[key] = attribVal;
    //         }
    //     }
    //     return result;
    // }

    decode (s: string) {
            return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
    }

    //NOTE: Setting document.cookie will only add/replace the values defined by key=value; key2=value, and will not replace/remove existing values with other keys
    //also, currently this app uses Google Analytics which sets cookies with ID _ga and _gid
    setCookie (key: string, value: any, attributes: StringAnyMap = {}) {
        if (!this.hasDoc) {
            return;
        }
        try {
            key = key.toString(); //in case called from plain JS
            value = value.toString();
            
            attributes = $.extend({
                path: '/'
            }, this.cookieAttributeDefaults, attributes);

            let expires = attributes.expires;

            if (typeof expires === 'number') {
                expires = this.getDaysFromNow(expires).toUTCString();
            } else if (expires instanceof Date) {
                expires = expires.toUTCString();
            }

            // We're using "expires" because "max-age" is not supported by IE
            attributes.expires = attributes.expires ? attributes.expires.toUTCString() : '';

            try {
                let result = JSON.stringify(value);
                if (/^[\{\[]/.test(result)) {
                    value = result;
                }
            } catch (e) {}

            if (this.writeCookieConverter) {
                value = this.writeCookieConverter(value, key);
            } else {
                value = encodeURIComponent(value)
                    .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);
            }

            key = encodeURIComponent(key)
                .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
                .replace(/[\(\)]/g, escape);

            let stringifiedAttributes = '';
            for (let attributeName in attributes) {
                if (!attributes[attributeName]) {
                    continue;
                }
                stringifiedAttributes += '; ' + attributeName;
                if (attributes[attributeName] === true) {
                    continue;
                }

                // Considers RFC 6265 section 5.2:
                // ...
                // 3.  If the remaining unparsed-attributes contains a %x3B (";")
                //     character:
                // Consume the characters of the unparsed-attributes up to,
                // not including, the first %x3B (";") character.
                // ...
                stringifiedAttributes += '=' + attributes[attributeName].split(';')[0];
            }

            //NOTE: setting document.cookie will ONLY add/replace the values for the specified keys (not ALL the cookies)
            document.cookie = key + '=' + value + stringifiedAttributes;
        } catch (error) {
            log.error(`Failed to set cookie ${key} to value ${value}`, LogLevel.Error, error);
        }
    }
    
    //can return undefined, string, or json parsed object
    getCookieJson(key: string): any {
        return this.getCookies(key, true, false)[key];
    }
    getCookie(key: string): string {
        return this.getCookies(key, false, false)[key] || '';
    }

    getCookies (key: string, jsonParse = false, returnAllIfEmptyKey = true): StringMap | StringAnyMap {
        
        //the result object, with object values either strings or any (if jsonParse = true)
        let jar: StringMap | StringAnyMap = jsonParse ? {} as StringMap : {} as StringAnyMap;

        if (!this.hasDoc) {
            return jar;
        }
        let returnAll = false;
        if (!key) {
            if (returnAllIfEmptyKey) {
                returnAll = true;
            } else {
                return jar;
            }
        }

        // To prevent the for loop in the first place assign an empty array
        // in case there are no cookies at all.
        let cookiesRaw = this.tryGetCookiesRaw();
        if (!cookiesRaw) {
            return jar;
        }
        let cookies = cookiesRaw.split('; ');
        let i = 0;

        for (; i < cookies.length; i++) {

            let parts = cookies[i].split('=');
            let cookie = parts.slice(1).join('=');

            if (!jsonParse && cookie.charAt(0) === '"') {
                cookie = cookie.slice(1, -1);
            }

            try {
                let name = this.decode(parts[0]);
                if (this.readCookieConverter) {
                    cookie = this.readCookieConverter(cookie, name);
                } else {
                    cookie = this.decode(cookie);
                }
                if (jsonParse) {
                    try {
                        cookie = JSON.parse(cookie);
                    } catch (e) {

                    }
                }

                jar[name] = cookie;

                if (!returnAll && key === name) {
                    break;
                }
            } catch (e) {
                
            }
        }

        return jar;
    }

}

//crushvars should go here
class AppState {
    constructor() {
    }
}

// #endregion Enums (4)


// #region Classes (8)

class ColumnSettings {
    // #region Properties (7)

    name: string;
    usage: ColumnUsage;
    dataType: DataType;
    aggregate: AggregateMethod;
    index = -1; //ensure to set this, as the index into the TableRow
    isCurrency: boolean;
    isExportable = true;
    isReportable = true;

    // #endregion Properties (7)

    constructor(name = '', usage = ColumnUsage.Default, aggregate = AggregateMethod.Default, dataType = DataType.Unknown, index = -1) {
        this.name = name;
        this.usage = usage;
        this.aggregate = aggregate;
        this.dataType = dataType;
        this.index = index;
    }
}


class TableInputData {

    name: string;
    columns: ColumnSettings[] = [];
    rows: any[][] = [];
    constructor(rows: any[][] = [], columns: ColumnSettings[] = [], name = '') {
        this.rows = rows;
        this.columns = columns;
        this.name = name;
    }
}

class TableView {

    // #endregion Constructors (1)

    // #region Public Accessors (2)

    get name(): any {
        return this._name;
    }
    set name(newName: any) {
        const oldName = this._name;
        this._name = newName;
        //check if value has changed to see if need to require recalculation
        if (oldName !== newName) {
            this.onNameChanged(oldName, newName);
        }
    }

    public get columnCount(): number {
        return this.columns.length;
    }

    static readonly SubViewDelim = '__';
    static readonly ExportSubViewName = 'Export';
    static readonly ExportSuffix = TableView.SubViewDelim + TableView.ExportSubViewName;
    // #region Properties (7)

    private _name: string;

    columns: ColumnSettings[];
    exportView: TableView;
    //the order of columns shown and which to show
    usage: ViewUsage;

    // #endregion Properties (7)

    // #region Constructors (1)

    //optional derived view for exporting of a report
    constructor(usage: ViewUsage, name = '', columns: ColumnSettings[] = []) {
        this.usage = usage;
        this.name = name;
        this.columns = columns;
    }

    // #endregion Public Accessors (2)

    // #region Public Methods (4)

    createExportSubView(): TableView {
        return this.createSubView(TableView.ExportSubViewName, true);
    }

    createSubView(subViewName = '', isExport: boolean = (subViewName === TableView.ExportSubViewName)): TableView {

        let usage = this.usage | ViewUsage.SubView;  // tslint:disable-line: no-bitwise
        if (isExport) {
            //add the Export flag so that usage includes ViewUsage.ExportSubView;
            usage |= ViewUsage.Export; // tslint:disable-line: no-bitwise
            subViewName = TableView.ExportSubViewName;
        }
        const name = this.name + TableView.SubViewDelim + subViewName;
        //filter columns to remove any which shouldn't be exported or shown in report (depending on the subview type)
        //however, users can still customize/override those settings in this view definition
        const cols = this.columns.filter((c) => { return isExport ? c.isExportable : c.isReportable; });
        //LATER: May need to propagate changes at some point if user changes global default for whether to show/hide/include in report/export a column, applying that to all saved reports/subviews
        //create the view
        const view = app.views.create(usage, name, true, cols);
        if (isExport) { //NOTE: currently assumes only have one Export subview, vs. export subsubview per subview
            this.exportView = view;
        }
        return view;
    }

    delete() {
        app.views.remove(this);
    }

    usageIncludes(usage: ViewUsage, exactNotSubType = false): boolean {
// tslint:disable-next-line: no-bitwise
        return exactNotSubType ? (this.usage === usage) : ((this.usage & usage) === usage);
    }

    // #endregion Public Methods (4)

    // #region Private Methods (1)

    private onNameChanged(oldName: string, newName: string) {
        app.views.internalNotifyViewNameChanged(this, oldName, newName);
    }

    // #endregion Private Methods (1)
}

class TableViewSet {
    // #region Properties (8)

    private byName: { [key: string]: TableView; } = {};

    all: TableView[] = [];
    apiOutput: TableView;
    //same as dataPrep currently. the master columns definition
    dataPrep: TableView;
    dataPrepExport: TableView;
    //Map<string, TableView>
    //NOTE: Currently avoiding Map class which isn't supported by IE, and has some syntax issues type TypeScript generics, so just using an object to map it
    default: TableView;
    fullReport: TableView;
    selectionReport: TableView;

    // #endregion Properties (8)

    // #region Constructors (1)

    //userReports: TableView[]; //can just filter instead. Should probably also include selectionReport and fullReport
    constructor() {
        //this.init(); //require manually calling this after set PowerAnalytics.views = this, to avoid circular dependency order of execution issues
    }

    // #endregion Constructors (1)

    // #region Public Methods (8)

    init() {
        this.createDefaults();
    }

    createDefaults() {
        //create the default master View used for Data Prep (currently ViewUsage.DataPrep == ViewUsage.Default)
        this.dataPrep = this.default = this.create(ViewUsage.DataPrep);
        this.dataPrepExport = this.dataPrep.createExportSubView();
        this.apiOutput = this.create(ViewUsage.ApiOutput);
        this.selectionReport = this.create(ViewUsage.DefaultSelectionReport);
        this.fullReport = this.create(ViewUsage.DefaultFullReport);
    }

    create(usage: ViewUsage, name = '', autoGenNameIfNotUnique = true, columns?: ColumnSettings[]): TableView {
        if (!name || autoGenNameIfNotUnique) {
            const warn = ! !name; //warns only if name was already requested specifically
            name = this.generateUniqueName(usage, name, warn);
        } else if (this.byName[name]) {
            //warn if already exists and not allowed to auto-generate
            log.warn(`Duplicate Report/View Name:Replacing old report with new one named '${name}' of type '${ViewUsage[usage]}'`);
        }
        let view = new TableView(usage, name, columns);
        this.all.push(view);
        //key view under name for quick lookups
        this.byName[name] = view;
        //TODO: Especially with delayed renaming, may need to auto-generate a name or at least implement T
        //TODO: Handle TableView Name change via Name property setter to cause re-inserting under new key.
        //LATER: Can warn about duplicate names
        return view;
    }
    createReport(isSelectionReport = false, name = ''): TableView {
        return this.create((isSelectionReport ? ViewUsage.CustomSelectionReport : ViewUsage.CustomSelectionReport), name);
    }

    generateUniqueName(usage: ViewUsage = ViewUsage.Unknown, baseName: string = ViewUsage[usage], warnIfGenerated = false): string {
        let name = baseName; //default to just "Report" or "Export" or "Full Report", etc. without a number suffix
        let num = 2;
        let generated = false;
        //continue generating names until found one not in use
        while (this.byName[name]) {// if already exists for this name
            name = baseName + ' ' + num; //defaults to "Report 2" only if "Report" already exists
            num = num + 1; //increment for next test if this one fails
            generated = true;
        }
        if (warnIfGenerated && generated) {
            log.warn(`Duplicate Report:Already have View/Report named '${baseName}' so had to auto - generate new unique name '${name}' for this View/Report of type '${ViewUsage[usage]}'`);
        }
        return name;
    }

    //lookup View by name or index
    getView(nameOrIndex: string | number): TableView | undefined {
        if (typeof (nameOrIndex) === 'number') {
            return this.all[nameOrIndex as number];
        } else {
            return this.byName[nameOrIndex as string];
        } //OR: .toString();
    }

    has(nameOrIndex: string | number): boolean {
        return ! !this.getView(nameOrIndex);
    }

    //@internal requires --stripInternal compiler flag for TypeScript to ensure don't export
    /** @internal */
    internalNotifyViewNameChanged(view: TableView, oldName: string, newName: string) {
        const found = this.byName[oldName];
        if (found && found !== view) {
            log.warn(`Duplicate View/Report name: Another view '$ {found}' had old name '$ {oldName}' before renamed to '$ {newName}'`);
        } else {
            delete this.byName[oldName];
        }
        const newFound = this.byName[newName];
        if (newFound && newFound !== view) {
            log.warn(`Duplicate View/Report name: Another view '$ {found}' had new name '$ {newName}' after renamed from '$ {oldName}'`);
        }
        //key under the new name, replacing even an existing one with same name
        this.byName[newName] = view;
    }

    remove(view: TableView): boolean {
        if (!view) return false;
        let found = false;
        //safest not to check via has() or byName first, in case of duplicates or left overs
        const index = this.all.indexOf(view);
        if (index >= 0) {
            this.all = this.all.splice(index, 1);
            found = true;
        }
        if (this.byName[view.name] == view) {
            delete this.byName[view.name];
            found = true;
        }
        return found;
    }

    // #endregion Public Methods (8)
}


class RowField {
    // #region Properties (9)

    //legacy Value is often a string like "T BRIAN" but sometimes an number[] (Array) for row numbers, etc.
    private _htmlValue: string | undefined = undefined;
    //converted to text
    private _legacyValue: any = undefined;
    //OR: string | number | Date | undefined | number[].
    private _textValue: string | undefined = undefined;
    private _value: any = undefined;

    column: ColumnSettings;

    groupedFields: RowField[];
    groupedUniqueValueCouunt: number;
    //table row this is a field of. This would probably be left or right table so also need mergedRow, if any.
    row: TableRow;
    //the original left/right table this came from, only if this is in a merged row
    sourceRow: TableRow;

    // #endregion Properties (9)

    constructor(column: ColumnSettings, row: TableRow, value: any) {
        this.column = column;
        this.row = row;
        this.value = value;
    }
    // #region Public Accessors (7)

    // textValue property getter/setter:
    get htmlValue(): string {
        if (this._htmlValue === undefined) {
            if (this._value === undefined) {
                this._htmlValue = '';
            } else {
                this._htmlValue = this.textValue; //TODO: Other formatting as needed
            }
        }
        return this._htmlValue || '';
    }

    //MAYBE: setValue(ValueFormat) if needed?
    // legacyValue property getter/setter:
    get legacyValue(): any {
        if (this._legacyValue === undefined) {
            //TODO: Construct legacy value here, Just-in-Time, only if/when needed
            //this._legacyValue = …
        }
        return this._legacyValue;
    }

    set legacyValue(newLegacyValue: any) {
        //TODO: Parse this and set this.value (using property setter for event handling etc.) based on it if it's in legacy format like "T BRIAN" or even if supposed to be a number but is a text, etc.
        this.value = newLegacyValue;
    }

    // textValue property getter/setter:
    get textValue(): string {
        if (this._textValue === undefined) {
            if (this._value === undefined) {
                this._textValue = '';
            } else {
                this._textValue = this._value.toString();
            }
        }
        return this._textValue || '';
    }

    set textValue(newTextValue: string) {
        //TODO: Parse this and set this.value (using property setter for event handling etc.) based on it if it's in legacy format like "T BRIAN" or even if supposed to be a number but is a text, etc.
        //TODO: Parse this if column is not of text type
        this.value = newTextValue;
    }

    get value(): any {
        return this._value;
    }

    set value(newValue: any) {
        const oldValue = this._value;
        this._value = newValue;
        //invalidate cached alternative versions so recreated if/when needed
        this._legacyValue = undefined;
        this._htmlValue = undefined;
        if (typeof (newValue) === 'string') {
            this._textValue = newValue;
        } else {
            this._textValue = undefined;
        }
        //check if value has changed to see if need to require recalculation
        if (oldValue !== newValue) {
            //MAYBE: need to check if it's an array (like for Row # Array) and compare elements instead then?)
            //MAYBE: Consider whether should use != instead of exact !== in case setting string value when
            // underlying type is a number, etc. For now just trigger recalc to be safe though.
            //Or maybe compare against last known _textValue if types are the same. Maybe we can move the invalidation setting of cached fields here so only occurs if/when needed, if deemed safe
            this.onValueChanged(oldValue, newValue);
        }
    }

    // #endregion Public Accessors (7)

    // #region Public Methods (3)

    getValue(format: ValueFormat): any {
        switch (format) {
            case ValueFormat.Raw:
                return this.value;
            case ValueFormat.Text:
                return this.textValue;
            case ValueFormat.Legacy:
                return this.legacyValue;
            case ValueFormat.UniqueValueArray:
                //TODO: handle grouping to determine all values
                return [this.value];
            case ValueFormat.HTML:
                //TODO: Includes markup for (+4) for multiple unique values
                return this.htmlValue;
            default:
                return this.value;
        }
    }

    //set htmlValue(newHTMLValue: string) {} //don't allow setting HTML value, unless needed for inline cell value editing, etc.
    onGroupSummaryChanged() {
        //TODO: Can set this._htmlValue = undefined if want to recalculate if # of unique values if column.usage == firstOf or data type is text and have multiple values
        //this._htmlValue = undefined
    }

    onValueChanged(oldValue: any, newValue: any) {
        //TODO LATER: trigger events, invalidate, request re-analyze if needed (if not analyzing now), or handle data binding, etc here later
    }

    // #endregion Public Methods (3)
}

class TableRow {
    // #region Properties (4)

    //a RowField wrapping the Row Numbers array for left (index=0), right, etc. input tables (after transformations)
    //all merged fields, including RowField representing Row Num's. Matches ColumnSettings.columnIndex,
    //so don't need to use columnName, originalColumName, etc. as keys (overhead and can change dynamically)
    fields: RowField[];
    //OR: name as rowIndex for clarity though longer
    fieldsPerTable: TableRow[];
    rowNum: number;
    //a TableRow for each InputTable, so can do fieldsPerTable[0][0] to get first field value of left input table
    rowNumsByTable: RowField[];

    //Define index signature for this type so can use this[number] = legacyValue.
    //TODO: Remove once removed legacy value use
    [legacyValueIndex: number]: any;

    // #endregion Properties (4)

    // #region Public Methods (4)

    forView(view: TableView = app.views.default): RowField[] {
        const viewFields: RowField[] = [];
        viewFields.length = view.columns.length;
        //for each column in the view, get the row's field in that order
        for (let i = 0; i < view.columns.length; i++) {
            const col = view.columns[i];
            const field = this.fields[col.index];
            viewFields[i] = field;
        }
        return viewFields;
    }


    //TableRow.init() ensures the TableRow can be used as drop-in replacement for arrays in rowpairArr
    //by allowing rowpairArr[row][fieldIndex] with same legacy format like "T BRIAN", etc. and even including Row# Array for 2nd and 3rd elements, by using index # as property name.
    //BACKWARDS COMPATIBLE: In-place drop-in for rowpairsArr.
    //LATER: Remove all dependencies on this
    initLegacy() {
        //for backwards compatibility until finish refactoring, add set each field value at the index
        //that existing code expects it to be, converting FieldValue class to just a value in format required like "T BRIAN". Then incrementally remove dependency on that
        //get the view defining fields to include and order of them for Data Prep tab/panel, representing the default view
        const legacyFormatValuesInViewOrder = this.valuesForView(app.views.default, ValueFormat.Legacy); //use the default view (Data Prep)
        for (let i = 0; i < legacyFormatValuesInViewOrder.length; i++) {
            const legacyValue = legacyFormatValuesInViewOrder[i];
            this[i] = legacyValue;
        }
    }

    //if legacyFormat = true, then return like "T BRIAN" for text, etc. like expected for rowpairsArr
    valuesForFields(fields: RowField[], format = ValueFormat.Raw): any[] {
        //presize the array optimization
        const vals: any[] = [];
        vals.length = fields.length;
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            vals[i] = fields[i].getValue(format);
        }
        return vals;
    }

    //MAYBE: Also below which can possibly replace need for (or be used together with) rowNumByTable?
    //sourceRows: TableRow[]; If have a TableRow created for each input table (though can rename TableRow class if confusing)
    //MAYBE: can implement later if helpful:
    //group: RowGroup;
    //groupNum: number;
    valuesForView(view: TableView = app.views.default, format = ValueFormat.Raw): any[] {
        return this.valuesForFields(this.forView(view), format);
    }

    // #endregion Public Methods (4)
}

//TableData class. Derived from TableView. The runtime state alternative to the lightweight TableInputData class.
class TableData extends TableView {
    // #region Properties (4)

    rows: TableRow[] = [];
    private _originalData: TableInputData;
    isAvailable = false;
    isOutdated = true;

    public get isUsed() {
        return this.rowCount > 0 && this.columnCount > 0;
    }
     public get rowCount(): number {
        return this.rows ? this.rows.length : 0;
    }
    //for optimization, can presize if already known the number of rows by setting rowCount
    public set rowCount(newRowCount: number) {
        if (!this.rows) {
            this.rows = [];
        }
        this.rows.length = newRowCount;
       //MAYBE: Could set each row to a new TableRow class instance (so don't end up with errors when trying to use if increase size), like done when setting originalData, except empty, if this is even needed
   }


   public get originalData(): TableInputData {
        return this._originalData;
    }
    //for optimization, can presize if already known the number of rows by setting rowCount
    public set originalData(newOriginalData: TableInputData) {
        this._originalData = newOriginalData;
        if (!this._originalData) {
            return;
        }
        const rowCount = this.rowCount = this._originalData.rows.length;
        if (!this.rows) this.rows = [];
        this.rows.length = rowCount;

        //MAYBE: share the same columns array for now, see if safe (if want to sync with Excel, etc.) later
        this.columns = this._originalData.columns;
        const colCount = this.columns.length;

        //create a RowField for each row array value from InputTableData
        for (let r = 0; r < rowCount; r++) {
            const row = this.rows[r] = new TableRow();
            const rowData = this._originalData.rows[r];
            row.rowNum = r;

            for (let c = 0; c < colCount; c++) {
                row.fields[c] = new RowField(this.columns[c], row, rowData[c]);

                if (app.useLegacyValues) {
                    row.initLegacy();
                }
            }
        }

        this.init();
    }

    public init() {
        for (let c = 0; c < this.columns.length; c++) {
            this.columns[c].index = c;
        }
    }

    // #endregion Properties (4)

    // #region Constructors (1)

    //= true if isOutputDataAvailable = true
    constructor(usage: ViewUsage, name: string = ViewUsage[usage], inputData?: TableInputData, columns: ColumnSettings[] = []) {
        super(usage, name, columns);
        //call base class TableView's constructor above
        if (inputData) {
            //initialize rows with RowData[] from the values
            this.originalData = inputData;
        }
    }

    // #endregion Constructors (1)

    clearRows() {
        if (!this.rows) {
            this.rows = [];
        } else {
            this.rows.length = 0;
        }
        this.isAvailable = false;
        this.isOutdated = true;
    }

    //clears rows and columns
    clear() {
        this.clearRows();
        this.columns = []//replace array, don't set .length to avoid modifying the existing array in case was passed from Excel
    }
}

// #endregion Classes (8)



// #region PowerAnalytics class (global service)

//PowerAnalytics class. The Primary class representing PowerAnalytics and storing its globals and providing its API

class PowerAnalytics {

    //Properties:
    public get leftTable(): TableData | undefined {
        return this.inputTables && this.inputTables.length > this.leftTableIndex ? this.inputTables[this.leftTableIndex] : undefined;
    }
    public set leftTable(newTable: TableData | undefined) {
        this.setTable(this.leftTableIndex, newTable)
    }
    public get mergedRows(): TableRow[] {
        return this.mergedTable.rows;
    }
    public set mergedRows(rows: TableRow[]) {
        this.mergedTable.rows = rows;
    }

    public get rightTable(): TableData | undefined {
        return this.inputTables && this.inputTables.length > this.rightTableIndex ? this.inputTables[this.rightTableIndex] : undefined;
    }
    public set rightTable(newTable: TableData | undefined) {
        this.setTable(this.rightTableIndex, newTable)
    }

    public get hasLeftTable(): boolean {
        return !!this.leftTable;
    }
    public get hasRightTable(): boolean {
        return !!this.rightTable;
    }

    //Singleton static instance:

    static instance: PowerAnalytics;
    // #region Properties (10)

    //Constants:

    readonly mergedTableIndex: number = -1;
    readonly leftTableIndex: number = 0;
    readonly rightTableIndex: number = 1;
    //TODO: Remove this after finish refactoring and removing rowpairsArr, finding references to it to do so.
    readonly useLegacyValues: boolean = true;

    //Fields:

    //crushvars
    mergedTable: TableData;
    inputTables: (TableData | undefined)[] = [];
    state: AppState;
    views: TableViewSet;
    log: Logger; //can't rename to "log" otherwise will prevent from being able to set the global variable named log, since will hide that
    persist: Persistence;
    util: Utility;

    //Constructor:

    constructor() {
        //set app global variable and instance static field to this new instance of this class
        //MUST be first line of constructor
        //and must not have inline fields create class instances (like state: AppState = new AppState()) before this
        PowerAnalytics.instance = this; //set static singleton field to this new instance
        app = this; //set global app variable to this new instance

        //ensure the global variable "log" is initialized
        if (!log) {
            log = new Logger();
        }
        //set this logger field to global log variable
        this.log = log;
        this.util = new Utility();
        this.persist = new Persistence();

        this.state = new AppState();
        this.views = new TableViewSet();

        this.init();
    }

    // #endregion Properties (10)

    // #region Public Methods (4)

    public init() {
        this.views.init();
        this.createDefaultTables();
    }

    public createDefaultTables() {
        this.createTable(this.mergedTableIndex);
        this.createTable(this.leftTableIndex);
        this.createTable(this.rightTableIndex);
    }

    public createTable(index: number, inputDataOrRows?: TableInputData | any[][], columns?: ColumnSettings[], name = '', usage = this.usageForTableIndex(this.mergedTableIndex)) {

        const table = new TableData(usage);

        if (inputDataOrRows) {
            let input: TableInputData;
            if (inputDataOrRows instanceof TableInputData) {
                input = inputDataOrRows as TableInputData;
                if (columns && columns.length) {
                    input.columns = columns;
                }
                if (name) {
                    input.name = name;
                }
            } else {
                input = new TableInputData(inputDataOrRows as any[][], columns, name);
            }

            table.originalData = input;
        }

        this.setTable(index, table);
    }

    //set mergedTable if index < 0 or else inputTables at index. If newTable is undefined, then remove it.
    public setTable(index: number, newTable?: TableData) {
        if (!this.inputTables) this.inputTables = [];

        //MAYBE: Can handle index=-1 as meaning mergedTable?

        //if removing a table, setting to undefined or null
        if (!newTable) {
            if (this.inputTables.length == (index + 1)) {
                this.inputTables.length = index; //reduce size by 1
                return;
            } else if (index >= this.inputTables.length) {
                //ignore, since never contained this table, don't need to remove it
                return;
            } else { //also includes index = -1 for mergedTable case
                //replace with empty table (with isUsed = false), since can't set to undefined (don't want to leave a hole and would force changing field type to TableData | undefined)
                newTable = new TableData(this.usageForTableIndex(index));
            }
        } else {
            newTable.usage = this.usageForTableIndex(index);
            newTable.init(); //set index for each ColumnSettings
            //MAYBE: Set name from usage too if wasn't already specified??
        }

        if (index < 0) {
            this.mergedTable = newTable;
        } else {
            //replace existing table
            this.inputTables[index] = newTable;
        }

    }

    public usageForTableIndex(tableIndex: number): ViewUsage {
        switch (tableIndex) {
            case -1:
                return ViewUsage.Default; //== DataPrep
            case 0:
                return ViewUsage.LeftTable;
            case 1:
                return ViewUsage.RightTable;
            default:
                return tableIndex < 0 ? ViewUsage.Default : ViewUsage.InputTable;
        }
    }
    // #endregion Public Methods (4)
}

// #endregion PowerAnalytics class (global service)

// #region Module Execution

//create instance of PowerAnalytics primary global service class.
//NOTE: This constructor will set "app" global variable (as well as PowerAnalytics.instance) to this instance in this constructor before anything else, since setting after constructor is too late
//for where app variable is used by constructors of other types created in PowerAnalytics constructor, like TableData, etc.
// tslint:disable-next-line: no-unused-expression
new PowerAnalytics();

// #endregion Module Execution

